<template>
  <Base></Base>
</template>


<script>
import Base from './components/Base.vue';


export default {
  components:{
    Base
  },
}
</script>
